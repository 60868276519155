.landing-intro {
  background: $color-bg-100;
  padding: 0 16px 0;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
  }
  &::before {
    bottom: -44px;
    right: 50%;
    margin-right: -36px;
    width: 462px;
    height: 462px;
    background: radial-gradient(50% 50% at 50% 50%, #a244ff 0%, rgba(68, 114, 255, 0) 100%);
    filter: blur(100px);
  }
  &::after {
    bottom: -52px;
    left: 50%;
    width: 330px;
    height: 330px;
    background: radial-gradient(50% 50% at 50% 50%, #e544ff 0%, rgba(255, 68, 68, 0) 100%);
    filter: blur(125px);
  }

  &__wrapper {
    max-width: 960px;
    margin: 0 auto;
    padding: 208px 0 0;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
  }

  &__illustration {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    .img {
      position: absolute;
      z-index: 5;
      transform: translateY(calc(var(--parallax-pow, 1) * var(--scroll-delta, 0) * 0.5));
      @media (max-width: 720px) {
        opacity: 0.25;
      }

      &--01 {
        left: 0;
        margin-left: -24px;
        top: 40.5%;
        width: 260px;
        z-index: 5;
        --parallax-pow: 1.2;
      }
      &--02 {
        left: 2%;
        top: 10%;
        width: 300px;
        z-index: 4;
        --parallax-pow: 0.75;
      }
      &--03 {
        left: 22.32%;
        top: 11.22%;
        width: 183px;
        z-index: 3;
        --parallax-pow: 0.4;

        @media (max-width: 1024px) {
          display: none;
        }
      }
      &--04 {
        left: 32.62%;
        top: 50%;
        width: 122px;
        z-index: 2;
        --parallax-pow: 0.25;

        @media (max-width: 1024px) {
          display: none;
        }
      }
      &--05 {
        left: 50%;
        margin-left: -68px;
        top: 3.8%;
        width: 166px;
        z-index: 2;
        --parallax-pow: 0.75;
      }
      &--06 {
        right: 22.62%;
        top: 40.5%;
        width: 193px;
        z-index: 3;
        --parallax-pow: 0.5;

        @media (max-width: 1024px) {
          top: 30%;
        }
      }
      &--07 {
        right: 11.3%;
        top: 10%;
        width: 300px;
        z-index: 4;
        --parallax-pow: 0.7;

        @media (max-width: 1024px) {
          display: none;
        }
      }
      &--08 {
        right: 0;
        margin-right: -24px;
        top: 36%;
        width: 284px;
        z-index: 5;
        --parallax-pow: 1.1;
      }
    }
  }

  &__header {
    position: relative;
    z-index: 3;
    margin: auto 0;
    transition: opacity 0.25s ease;
  }
  &__title {
    font-size: 56px;
    line-height: 68px;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }

  &__subtitle {
    margin: 24px auto 0;
    font-size: 16px;
    line-height: 26px;
    display: block;
    text-align: center;
    max-width: 620px;
  }

  &__header + &__body {
    margin-top: 40px;
  }

  &__body {
    transition: opacity 0.25s ease;
  }

  .action--main {
    &::before,
    &::after {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      z-index: -1;
      pointer-events: none;
      @media (min-width: 720px) {
        content: '';
      }
    }
    &:before {
      width: 337px;
      height: 215px;
      left: -255px;
      top: -37px;
      background-image: url('../../images/landing/joistick_left.png');
    }
    &:after {
      width: 387px;
      height: 204px;
      right: -328px;
      top: -7px;
      background-image: url('../../images/landing/joistick_right.png');
    }
  }

  &--no-auth & {
    &__header,
    &__body {
      opacity: 0;
    }
  }
}
