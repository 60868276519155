@keyframes article-entry-hover-slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.articles {
  &__footer {
    margin-top: 80px;
  }
  &__list {
    list-style: none;
    margin: -24px 12px 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;

    @media (max-width: 720px) {
      flex-flow: column;
    }
  }
  &__item {
    margin: 24px 0 0;
    padding: 0 12px;
    width: 33.3333%;
  }
  &__list:not(.is--shortcut) > &__item {
    width: 50%;
    width: calc(50% - 1px);

    &:first-child {
      width: 60%;
      width: calc(60% - 1px);
    }
    &:nth-child(2) {
      width: 40%;
      width: calc(40% - 1px);
    }
    @media (max-width: 720px) {
      &,
      &:first-child,
      &:nth-child(2) {
        width: 100%;
        position: static !important;
        transform: none !important;
      }
    }
  }
  &-actions {
    &__body {
      text-align: center;
    }
  }
  &__action {
    min-width: 120px;
  }
  &-entry {
    border-radius: 24px;
    padding: 2px;
    overflow: hidden;
    display: block;
    position: relative;
    color: $color-style-white;
    transform: translateZ(0);
    transition: all 0.25s ease;
    font-size: 16px;
    line-height: 1.5;
    &::before {
      content: "";
      background: linear-gradient(
        90deg,
        #9488f0 0%,
        #e967c4 25%,
        #82cf8a 50%,
        #e967c4 75%,
        #9488f0 100%
      );
      background-size: 50%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 400%;
      opacity: 0;
    }
    &:hover {
      text-decoration: none;
      color: $color-style-white;
      &::before {
        opacity: 1;
        animation: article-entry-hover-slide 3s linear infinite;
      }
    }
    &__wrapper {
      border-radius: 22px;
      position: relative;
      padding: 32px;
      background: $color-bg-100;
      transform: translateZ(0);
      & > * {
        position: relative;
        z-index: 1;
      }
    }
    &:hover > &__wrapper {
      overflow: hidden;
    }
    &__illustration {
      position: static;
      display: inline;
      &::before {
        content: "";
        position: absolute;
        left: -2px;
        top: -2px;
        right: -2px;
        bottom: -2px;
        background: inherit;
        pointer-events: none;
        opacity: 1;
        transition: all 0.25s ease;
      }
      .media {
        position: relative;
        &__wrapper {
          height: 180px;
        }
        &__item {
          width: 100%;
          max-height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
    &__header:not(:first-child) {
      @media (min-width: 960.1px) {
        margin: 32px 0 0;
      }
    }
    &__body:not(:first-child) {
      margin-top: 16px;
    }
    &__title {
      font-size: 24px;
      line-height: 1.3;
    }
    &:hover &__illustration {
      &::before {
        opacity: 0.6;
      }
    }
  }
  @media (min-width: 720.1px) {
    &__list:not(.is--shortcut) &__item:first-child &-entry {
      &__illustration {
        .media {
          padding: 8px 0 0;
          &__wrapper {
            height: 20rem;
          }
        }
      }
      &__header:not(:first-child) {
        margin-top: 16px;
      }
      &__title {
        font-size: 40px;
      }
    }
    &__list:not(.is--shortcut) &__item:nth-child(2) &-entry {
      &__illustration {
        .media {
          &__wrapper {
            height: 14rem;
          }
        }
      }
    }
  }
  @media (max-width: 960px) {
    &__list.is--shortcut &__item {
      width: 100%;
    }
    &__list.is--shortcut &-entry {
      &__illustration {
        margin: 0;
        .media {
          display: none;
        }
      }
    }
  }
}
