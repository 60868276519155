.header {
  padding: 8px;
  max-width: $content-width;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9999;

  @media (max-width: 800px) {
    padding: 12px 16px;

    .is--switched-menu & {
      background: $color-bg-80;
    }
  }

  &__logo {
    padding: 2px 0;

    @media (max-width: 800px) {
      padding: 0;
    }
    .logo {
      display: block;

      @media (max-width: 800px) {
        height: 32px;
        width: auto;
        object-fit: contain;
      }
    }
  }

  &__toggle {
    width: 32px;
    height: 32px;
    margin-left: auto;
    border: 0;
    background: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath stroke='%239488F0' stroke-linecap='round' stroke-width='2' d='M4 8h16M4 16h24M4 24h8'/%3E%3C/svg%3E")
      center center no-repeat;
    transition: all 0.25s ease;
    display: none;

    @media (max-width: 800px) {
      display: block;
    }

    &:hover,
    &:focus {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath stroke='%23AFA5FB' stroke-linecap='round' stroke-width='2' d='M4 8h16M4 16h24M4 24h8'/%3E%3C/svg%3E");
    }

    .is--switched-menu & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath stroke='%239488F0' stroke-linecap='round' stroke-width='2' d='M8 8l16 16M8 24L24 8.00001'/%3E%3C/svg%3E");

      &:hover,
      &:focus {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath stroke='%23AFA5FB' stroke-linecap='round' stroke-width='2' d='M8 8l16 16M8 24L24 8.00001'/%3E%3C/svg%3E");
      }
    }

    .caption {
      @include visually-hidden;
    }
  }

  &__body {
    margin-left: auto;
    display: flex;
    align-items: center;
    text-align: center;

    @media (max-width: 800px) {
      position: fixed;
      top: 56px;
      left: 0;
      width: 100%;
      bottom: 0;
      background: $color-bg-80;

      display: none;

      padding: 12px 16px;
      overflow: auto;

      flex-flow: column;
      align-items: stretch;
      justify-content: center;

      .is--switched-menu & {
        display: flex;
      }
    }
  }

  &__menu {
    margin-left: 16px;

    @media (max-width: 800px) {
      margin: 0;
      & + & {
        margin: 80px 0 0;
      }
    }
  }

  &-links {
    padding: 0;
    margin: 0 -4px;
    list-style: none;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @media (max-width: 800px) {
      flex-flow: column;
      align-items: stretch;
      justify-content: center;
      text-align: center;
    }

    &__item {
      margin: 0 4px;

      @media (max-width: 800px) {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }

    &__link {
      display: block;
      padding: 8px;
      cursor: pointer;

      &.active {
        color: $color-style-white;
      }
      @media (max-width: 800px) {
        padding: 2px 0;
      }
    }
  }

  &__wallet {
    margin-left: 16px;
    flex: 0 0 auto;

    @media (max-width: 800px) {
      margin: 16px auto 0;
    }
  }

  &-connect {
    display: inline-flex;
    align-items: center;
    color: $color-style-white;
    border-radius: 16px;
    background-color: $color-style-light-blue;
    cursor: pointer;
    padding: 6px 12px 6px 16px;
    transition: all 0.25s ease;
    &:hover {
      background-color: $color-hover-light-blue;
    }

    &__icon {
      margin: 0 12px;
      display: flex;
      align-items: center;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      path {
        stroke: $color-style-white;
      }
    }
  }
}
