html body .__react_component_tooltip {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  border-radius: 8px;
  padding: 16px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  max-width: 255px;
  transition: opacity 0.25s ease;
  z-index: 9999;
  background-color: $color-bg-100;
  color: $color-element-text-light;
  margin: 0;

  &::before {
    content: '';
    border-width: 8px !important;
    border-color: transparent;
    border-style: solid;
  }

  &:after {
    content: none;
  }

  & > * {
    margin: 0.5em 0;
  }

  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  &.place {
    &-top {
      margin-top: -8px;
      &:before {
        left: 50%;
        right: auto;
        top: 100%;
        bottom: auto;
        margin: 0 0 0 -8px;
        border-top-color: $color-bg-100;
      }
    }
    &-bottom {
      margin-top: 8px;
      &:before {
        left: 50%;
        right: auto;
        bottom: 100%;
        top: auto;
        margin: 0 0 0 -8px;
        border-bottom-color: $color-bg-100;
      }
    }
    &-left {
      margin-left: -8px;
      &:before {
        left: 100%;
        right: auto;
        top: 50%;
        bottom: auto;
        margin: -8px 0 0 0;
        border-left-color: $color-bg-100;
      }
    }
    &-right {
      margin-left: 8px;
      &:before {
        right: 100%;
        left: auto;
        top: 50%;
        bottom: auto;
        margin: -8px 0 0 0;
        border-right-color: $color-bg-100;
      }
    }
  }

  &.show {
    opacity: 1 !important;
    visibility: visible !important;
  }

  &.type {
    &-lite,
    &-light {
      background-color: $color-bg-60;
      color: $color-style-white;

      &.place {
        &-top {
          &::before {
            border-top-color: $color-bg-60;
          }
        }
        &-bottom {
          &::before {
            border-bottom-color: $color-bg-60;
          }
        }
        &-left {
          &::before {
            border-left-color: $color-bg-60;
          }
        }
        &-right {
          &::before {
            border-right-color: $color-bg-60;
          }
        }
      }
    }

    &-error {
      background-color: $color-style-red;
      color: $color-style-white;

      &.place {
        &-top {
          &::before {
            border-top-color: $color-style-red;
          }
        }
        &-bottom {
          &::before {
            border-bottom-color: $color-style-red;
          }
        }
        &-left {
          &::before {
            border-left-color: $color-style-red;
          }
        }
        &-right {
          &::before {
            border-right-color: $color-style-red;
          }
        }
      }
    }

    &-info {
      background-color: $color-style-blue-light;
      color: $color-style-white;

      &.place {
        &-top {
          &::before {
            border-top-color: $color-style-blue-light;
          }
        }
        &-bottom {
          &::before {
            border-bottom-color: $color-style-blue-light;
          }
        }
        &-left {
          &::before {
            border-left-color: $color-style-blue-light;
          }
        }
        &-right {
          &::before {
            border-right-color: $color-style-blue-light;
          }
        }
      }
    }

    &-warning {
      background-color: $color-style-purple;
      color: $color-style-white;

      &.place {
        &-top {
          &::before {
            border-top-color: $color-style-purple;
          }
        }
        &-bottom {
          &::before {
            border-bottom-color: $color-style-purple;
          }
        }
        &-left {
          &::before {
            border-left-color: $color-style-purple;
          }
        }
        &-right {
          &::before {
            border-right-color: $color-style-purple;
          }
        }
      }
    }
  }

  &.medium {
    max-width: 220px;
  }

  &.short {
    max-width: 180px;
  }
}
