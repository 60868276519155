.article {
  max-width: 744px;
  margin: 0 auto;

  &__header {
    margin-bottom: 72px;
    @media (max-width: 720px) {
      margin-bottom: 24px;
    }

    .meta {
      text-align: center;
    }
  }
  &__footer {
    border-top: 0.1rem $color-bg-60 solid;
    margin-top: 24px;
    padding-top: 42px;
  }
}
.text {
  max-width: 76rem;
  margin: 8px * 2 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  [href] {
    color: $color-style-purple;
    &:hover {
      color: $color-hover-purple;
    }
  }
  audio,
  video,
  iframe {
    max-width: 100%;
    height: auto !important;
    margin: 32px auto;
    object-fit: contain;
    max-height: 90vh;
    display: block;
  }
  p {
    margin: 32px 0;
    & > img:first-child:last-child {
      display: block;
      margin: 32px 0;
    }
  }
  img {
    max-width: 100%;
    border-radius: 32px;
  }
  & > img {
    display: block;
    margin: 32px 0;
  }
  @media (max-width: 640px) {
    [style*='float:left'],
    [style*='float: left'],
    [style*='float:right'],
    [style*='float: right'],
    .to--right,
    .right,
    [align='right'],
    .to--left,
    .left,
    [align='left'] {
      display: block;
      float: none !important;
      margin: 2rem auto !important;
    }
  }
  @media (min-width: 641px) {
    [style*='float:right'],
    [style*='float: right'],
    [style*='float:left'],
    [style*='float: left'],
    .to--right,
    .right,
    [align='right'],
    .to--left,
    .left,
    [align='left'] {
      max-width: 45%;
      height: auto;
      &:first-child + * {
        margin-top: 0;
      }
    }
    [style*='float:right'],
    [style*='float: right'],
    .to--right,
    .right,
    [align='right'] {
      float: right;
      margin: 0 0 8px * 2 8px !important;
    }
    [style*='float:left'],
    [style*='float: left'],
    .to--left,
    .left,
    [align='left'] {
      float: left;
      margin: 0 8px * 2 8px 0 !important;
    }
  }

  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  ul,
  ol {
    margin: 8px * 2 0;
    padding: 0 0 0 2em;
    list-style: none;
  }
  li {
    &:not(:last-child) {
      margin-bottom: 8px * 2;
    }
    &::before {
      clear: left;
      color: $color-style-purple;
      float: left;
    }
  }
  ul > li {
    &::before {
      content: '';
      width: 0.5em;
      height: 0.5em;
      margin: 0.55em 0 0 -2em;
      border-radius: 50%;
      background: currentColor;
    }
  }
  ol {
    counter-reset: counterOL;
    & > li {
      counter-increment: counterOL;
      &::before {
        margin: 0 0.5em 0 -2em;
        content: counter(counterOL) ' .';
        min-width: 1.5em;
      }
    }
  }
}
