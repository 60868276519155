.wallet {
  &-account {
    border: 0;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 4px;
    height: 40px;
    color: $color-style-white;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.25s ease;

    &--connected {
      padding: 4px 6px;
      background: $color-bg-90;

      &:hover {
        background-color: $color-element-btn-hover;
      }
    }

    &--open {
      pointer-events: none;
    }

    &__icon {
      display: flex;
      align-items: center;
    }
  }

  &__action:hover &__icon {
    svg {
      stroke: $color-hover-purple;
    }
  }

  &__action--connected &__icon {
    svg path {
      stroke: var(--dexguru-network-color, $color-element-text-light);
    }
  }

  &-avatar {
    &__image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
    }

    &__avimage {
      display: block;
    }
  }
}
