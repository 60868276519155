.footer {
  padding: 24px 8px 40px;
  max-width: $content-width;
  margin: 16px auto 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  color: $color-element-text;
  @media (max-width: 768px) {
    text-align: center;
  }

  & > * {
    margin: 16px 0 0;
    padding: 0 12px;
    @media (max-width: 768px) {
      flex: 0 0 100%;
    }
  }

  &-meta {
    display: inline-flex;
    margin: 0 -12px;
    white-space: nowrap;

    & > * {
      margin: 0 12px;
    }
  }

  &-links {
    &__list {
      margin: -12px -12px 0;
      padding: 0;
      list-style: none;
      display: inline-flex;
      flex-flow: row wrap;
      justify-content: center;
    }

    &__item {
      margin: 12px 0 0;
      padding: 0 12px;
    }
  }

  a {
    color: $color-style-purple;

    &:hover {
      color: $color-hover-lightpurple;
    }
  }
}
