@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap');

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
}

*::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: 4px transparent solid;
}
::-webkit-scrollbar-corner {
  opacity: 0;
  visibility: hidden;
}

*,
*:before,
*:after {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  min-width: 0;
}

body {
  background-color: $color-bg-100;
  color: $color-style-white;
  margin: 0;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
}

a {
  color: $color-element-text-light;
  transition: all 0.25s ease;
  text-decoration: none;

  &:hover {
    color: $color-hover-purple;
  }
}

h1 {
  text-align: center;
  color: $color-style-white;
  font-size: 56px;
  line-height: 1.3;
  @media (max-width: 768px) {
    padding: 36px;
    font-size: 36px;
  }
}

h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.3;
}

hr {
  margin: 8px 0;
  border: 0;
  height: 1px;
  border-top: 1px $color-bg-100 solid;
}

[data-rk] {
  --rk-colors-modalBackdrop: none !important;

  &[role='dialog'][aria-labelledby='rk_connect_title'] {
    & > [role='document'] {
      pointer-events: all;
      display: flex;
      flex-flow: column;
      gap: 40px;
      align-items: center;

      &::before {
        content: 'Developers Console';
        text-align: center;
        font-size: 56px;
        font-family: Sora, sans-serif;
        font-weight: 700;
        line-height: 120%;
        display: block;
      }
    }
  }
}
