.partners {
  &__image {
    height: 74px;
    max-width: 200px;
    object-fit: contain;
    display: block;
    margin: auto;
  }
  &__list {
    margin: -32px -32px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  &__item {
    margin: 32px 0 0;
    padding: 0 32px;
    list-style: none;
  }
  &__image {
    display: block;
    margin: 0 auto;
  }
}

.purple {
  color: $color-style-purple;
}
.grey {
  color: $color-style-grey;
}
.green {
  color: $color-style-green;
}
.blue {
  color: $color-style-lightblue;
}

.description {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  @media (min-width: 800.1px) {
    flex-flow: row wrap;
    margin: -32px -16px 0;
    align-items: center;
    & > * {
      margin: 32px 0 0;
      padding: 0 16px;
    }
  }
  @media (max-width: 800px) {
    & > :not(:first-child) {
      margin-top: 32px;
    }
  }
  &__illustration {
    position: relative;
    min-width: 50%;
    &::before {
      content: '';
      padding: 100% 100% 0 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.6);
      filter: blur(200px);
    }
    &--guru {
      @media (min-width: 800.1px) {
        width: 58%;
      }
    }
  }
  &__illustration:first-child {
    order: 0;
    @media (min-width: 800.1px) {
      order: 2;
    }
    &::before {
      background: radial-gradient(50% 50% at 50% 50%, #44ff9a 0%, rgba(68, 114, 255, 0) 100%);
    }
  }
  &__illustration:last-child {
    @media (min-width: 800.1px) {
      grid-column: 1;
    }
    &::before {
      background: radial-gradient(50% 50% at 50% 50%, #6046ff 0%, rgba(68, 165, 255, 0) 100%);
    }
  }
  &__body {
    order: 1;
    flex: 1 0 0;

    .text {
      & > :nth-child(even) {
        @media (min-width: 800.1px) {
          margin-left: 0;
          margin-right: 96px;
        }
      }

      &--altered {
        & > :nth-child(even) {
          @media (min-width: 800.1px) {
            margin-right: 0;
            margin-left: 96px;
          }
        }
      }
    }
  }
  &__illustration + &__body {
  }
  &__body + &__illustration {
    order: 0;
  }
  &__image {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 2;
    pointer-events: none;
  }

  &__illustration--data & {
    @media (min-width: 800.1px) {
      &__image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-45%, -30%);
      }
    }
  }
}

.chains {
  &__image {
    height: 80px;
    width: auto;
  }
}

.action--main {
  position: relative;
  z-index: 10;
  text-align: center;
  display: block;
  width: 344px;
  height: 236px;
  margin: 0 auto;
  text-decoration: none;
  background: none;
  padding: 0;
  border: 0;

  .caption {
    width: 100%;
    height: 100%;
    font-size: 18px;
    line-height: 1.1666;
    color: $color-style-white;
    display: block;
    padding: 50px 130px 0;
    background: url('../../images/landing/game_close.png') center top no-repeat;
    background-size: 100% auto;
  }

  &:hover {
    color: $color-style-white;

    .caption {
      padding-top: 32px;
      background-image: url('../../images/landing/game_open.png');
    }
  }
}

.preload-image {
  visibility: hidden;
  background-image: url('../../images/landing/game_open.png');
}
