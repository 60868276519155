.newsletter {
  margin: 120px auto 84px;
  padding: 0 8px;

  &__content {
    max-width: 680px;
    margin: 0 auto;
    width: 100%;
    border: 0;
    display: block;
    background: $color-style-white;
    border-radius: 16px;
    color: $color-bg-100;
  }
}
