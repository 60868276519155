@import './react-tooltip';

.projects {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
}

.project {
  background: $color-bg-80;
  border-radius: 16px;
  transition: all 0.25s ease;

  &--new {
    background: $color-bg-70;
  }

  &__header {
    padding: 24px 28px;
    border-bottom: 1px $color-bg-100 solid;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      width: 0;
      flex: 0 0 0;
      height: 56px;
    }
    @media (max-width: 768px) {
      padding: 8px 16px;
    }
  }

  &__title {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 0;
    cursor: pointer;
  }

  &__body {
    display: flex;
  }

  &__requests {
    padding: 32px 28px;
    border-right: 1px $color-bg-100 solid;
    display: flex;
    flex-flow: column;
    gap: 28px;
    flex: 0 0 auto;
    min-width: 40%;
    @media (max-width: 768px) {
      padding: 16px;
    }
  }

  &__key {
    padding: 28px;
    display: flex;
    flex-flow: column;
    gap: 28px;
    flex: 1 0 0;
    @media (max-width: 768px) {
      padding: 16px;
    }
  }

  &__value {
    font-size: 28px;
    font-weight: 600;
    height: 56px;
    display: flex;
    align-items: center;
    color: $color-element-text-light;
  }

  &__label {
    order: 1;
    font-size: 14px;
    font-weight: 400;
    color: $color-element-text-light;
  }

  &-apikey {
    display: flex;
    align-items: center;
    background: $color-element-input;
    color: $color-element-text;
    border-radius: 8px;
    font-weight: 400;
    position: relative;

    &__input {
      background: none;
      border: none;
      color: $color-style-white;
      font-size: 16px;
      flex: 1 0 0;
      height: 56px;
      padding: 12px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      &::-moz-placeholder {
        color: $color-element-text;
      }
      &::-webkit-placeholder {
        color: $color-element-text;
      }
      &::placeholder {
        color: $color-element-text;
      }
    }

    &__copy {
      width: 32px;
      height: 32px;
      padding: 0;
      margin-right: 16px;
      flex: 0 0 auto;
      background: none;
      border: 0;
      cursor: pointer;

      .caption {
        @include visually-hidden;
      }

      .icon {
        path {
          stroke: $color-element-text;
          transition: all 0.25s ease;
        }
      }

      &:hover {
        .icon {
          path {
            stroke: $color-hover-light-purple;
          }
        }
      }
    }
  }

  &-menu {
    position: relative;
    z-index: 10;
    &__toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background: none;
      border: 0;
      cursor: pointer;
      transition: all 0.25s ease;

      .icon {
        path {
          transition: all 0.25s ease;
        }
      }
      .caption {
        @include visually-hidden;
      }

      &:hover {
        background: $color-element-btn-hover;
      }
    }
    &__dropdown {
      position: absolute;
      right: 0;
      top: 100%;
      background: $color-bg-90;
      border-radius: 8px;
      overflow: hidden;
      z-index: 10;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transform: translateY(16px);
      transition: all 0.25s ease;
    }
    &__link {
      display: block;
      padding: 16px 20px;
      color: $color-style-white;
      background: none;
      transition: all 0.25s ease;
      cursor: pointer;

      &--danger {
        color: $color-style-red;
      }

      &:hover {
        background: $color-element-btn-hover;
      }
    }
    &--open & {
      &__toggle {
        background: $color-bg-60;
      }
      &__dropdown {
        margin-top: 16px;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
}

.projects-stats {
  background: $color-bg-80;
  border-radius: 16px;
  padding: 32px 28px;
  @media (max-width: 768px) {
    padding: 16px;
  }

  &__title {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
    flex: 1 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
  }
  &__body {
    &:not(:first-child) {
      margin-top: 24px;
    }
    @media (max-width: 768px) {
      display: none;

      &--expanded {
        display: block;
      }
    }
  }

  &--view {
    background-color: $color-bg-70;
  }

  &--view & {
    &__body {
      display: block;
    }
  }
  &--view &__header &__action {
    display: none;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: $color-bg-60;
    border: 0;
    cursor: pointer;
    transition: all 0.25s ease;
    .caption {
      @include visually-hidden;
    }

    &:hover {
      background-color: $color-element-hover-btn;
    }
  }

  @media (min-width: 768.1px) {
    &__header &__action {
      display: none;
    }
  }

  &__period {
    margin-bottom: 60px;
  }

  &__value {
    display: block;
    font-size: 28px;
    font-weight: 600;
    min-width: 220px;
    color: $color-style-purple;
    margin-bottom: 48px;
  }

  &-project {
    &__header {
      display: flex;
      align-items: center;
      border-bottom: 1px $color-bg-100 solid;
      margin: -32px -28px 28px;
      padding: 32px 28px;
      @media (max-width: 768px) {
        margin: -16px -16px 16px;
        padding: 16px;
      }
    }
    &__title {
      font-size: 20px;
      line-height: 1.5;
      font-weight: 400;
      margin: 0;
    }
    &__back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background: $color-bg-60;
      border: 0;
      cursor: pointer;
      transition: all 0.25s ease;
      margin-right: 24px;

      .icon {
        path {
          transition: all 0.25s ease;
        }
      }
      .caption {
        @include visually-hidden;
      }

      &:hover {
        background: $color-element-btn-hover;
      }
    }
  }
}

.project-control {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  width: 100%;

  &__name {
    position: relative;

    &--focus {
      margin: 1px;
      &::before {
        content: '';
        background: linear-gradient(
          135deg,
          rgba(131, 115, 255, 1) 0%,
          rgba(255, 87, 207, 1) 35%,
          rgba(58, 234, 181, 1) 100%
        );
        position: absolute;
        z-index: 0;
        left: -1px;
        top: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 8px;
      }
    }
  }
  &__input {
    background: $color-element-input;
    border: 0;
    color: $color-style-white;
    font-size: 16px;
    height: 56px;
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    position: relative;
    z-index: 1;
    &:focus {
      background: $color-bg-100;
      height: 54px;
      padding: 11px 15px;
    }
    &::-moz-placeholder {
      color: $color-element-text;
    }
    &::-webkit-placeholder {
      color: $color-element-text;
    }
    &::placeholder {
      color: $color-element-text;
    }
  }
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: $color-bg-60;
    border: 0;
    cursor: pointer;
    transition: all 0.25s ease;

    .icon {
      path {
        transition: all 0.25s ease;
      }
    }
    .caption {
      @include visually-hidden;
    }

    &:hover {
      background: $color-element-btn-hover;
    }
  }
}

.projects-panel {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  background: $color-bg-80;
  border-radius: 16px;
  padding: 16px;
  height: 100%;

  &__action {
    flex: 1 0 auto;
  }

  &__create {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      height: 40px;
      font-size: 14px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &__info {
    background: #232a32;
    border-radius: 16px;
    padding: 32px 28px;
  }

  &__plan {
    flex: 10 0 240px;
    display: flex;
    flex-flow: column;
    gap: 8px;
    justify-content: space-evenly;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    .title {
      flex: 0 0 auto;
    }

    .value {
      flex: 1 0 auto;
      color: $color-style-green;
    }

    .url {
      flex: 0 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $color-style-purple;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .free-flag {
      flex: 0 0 auto;
      margin: 0 -32px 0 16px;
      width: 68px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='68' height='24' fill='none'%3E%3Cpath fill='%236046FF' d='M68 0v24H0l6.094-12L0 0h68Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M31.761 16V8h2.943c.637 0 1.187.094 1.65.283.47.189.833.475 1.086.86.253.384.38.867.38 1.447v.175c0 .587-.13 1.07-.39 1.447a2.3 2.3 0 0 1-1.136.873L38.406 16h-1.802l-1.866-2.656h-1.434V16H31.76Zm1.542-3.908V9.317h1.564c.282 0 .525.058.727.174.21.116.373.28.489.49.116.21.174.454.174.73 0 .268-.058.507-.174.718-.116.21-.279.373-.489.49a1.44 1.44 0 0 1-.727.173h-1.564Z' clip-rule='evenodd'/%3E%3Cpath fill='%23fff' d='M25 16V8h5.104v1.36h-3.562v2.344l2.958-.01V13l-2.958.01V16H25ZM47.114 8v8H52v-1.306h-3.377v-2.08h3.095V11.31h-3.095V9.36H52V8h-4.886ZM40 8v8h4.886v-1.306h-3.377v-2.079h3.095v-1.306h-3.095V9.36h3.377V8H40Z'/%3E%3Crect width='8' height='1' x='11' y='7' fill='%23fff' rx='.5'/%3E%3Crect width='8' height='1' x='11' y='16' fill='%23fff' rx='.5'/%3E%3Cpath fill='%238CBAFF' d='M12 8h6s0 3-2.571 4C18 13 18 16 18 16h-6s0-3 2.571-4C12 11 12 8 12 8Z'/%3E%3C/svg%3E");
    }
  }

  &__property {
    display: flex;
    flex-flow: row wrap;
    gap: 0 0.25em;
  }
}
