.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  padding: 20px 0 20px 16px;
  z-index: 10000;
}

.content-modal {
  outline: none;
  border-radius: 16px;
  color: $color-style-white;
  position: relative;
  margin: auto;

  background-color: #1e242c;
}

.close-modal {
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10001;
  width: 32px;
  height: 32px;
  background-color: $color-bg-60;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.25s ease;

  &:hover {
    background-color: $color-element-btn-hover;
  }
}

.modal {
  &-overlay {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &-box {
    max-width: 500px;
    padding: 24px;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr;
    background: $color-bg-90;
    color: $color-element-text;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 16px;
    position: relative;

    &__header {
      display: flex;
    }
    &__title {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: $color-style-white;
      margin: 0 32px 0 0;
    }
    &__close {
      position: absolute;
      right: 12px;
      top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background: $color-bg-60;
      border: 0;
      cursor: pointer;
      transition: all 0.25s ease;

      .icon {
        path {
          transition: all 0.25s ease;
        }
      }
      .caption {
        @include visually-hidden;
      }

      &:hover {
        .icon {
          path {
            stroke: $color-hover-light-purple;
            fill: $color-hover-light-purple;
          }
        }
      }
    }
  }
}
