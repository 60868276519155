$content-width: 1368px;

.is--switched-menu {
  overflow: hidden;
}

@media (max-width: 800px) {
  html {
    height: 100%;
  }
  body {
    height: 100%;
  }
  #root {
    height: calc(100vh - (100vh - 100%));
  }
}

.page {
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: stretch;

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-width: 320px;
  }

  &__body {
    flex: 1 1 auto;
  }

  &__footer {
    flex: 0 0 auto;
  }
}

.cell {
  flex: 1 0 0;

  &--auto {
    flex: 0 0 auto;
  }

  &--grow {
    flex-grow: 1 !important;
  }

  &--shrink {
    flex-shrink: 1 !important;
  }

  &--grow-auto {
    flex-grow: 1;
    flex-basis: auto;
  }
}

.row {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  padding: 0;
  list-style: none;

  & > * {
    min-width: 0;
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }

  &--no-gap {
    margin: 0;

    & > * {
      margin: 0;
      padding: 0;
    }
  }

  &--gap-md {
    margin: -12px -6px 0;

    & > * {
      margin: 12px 0 0;
      padding: 0 6px;
    }
  }

  &--gap-sm {
    margin: -8px -4px 0;

    & > * {
      margin: 8px 0 0;
      padding: 0 4px;
    }
  }

  &--gap-xs {
    margin: -4px -2px 0;

    & > * {
      margin: 4px 0 0;
      padding: 0 2px;
    }
  }

  &--justify-start {
    justify-content: flex-start;
  }
  &--justify-end {
    justify-content: flex-end;
  }
  &--justify-between {
    justify-content: space-between;
  }
  &--justify-center {
    justify-content: center;
  }

  &--align-start {
    align-items: flex-start;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--align-stretch {
    align-items: stretch;
  }
  &--align-center {
    align-items: center;
  }

  &--cell-2 {
    & > * {
      flex: 0 0 50%;
    }
  }

  &--cell-3 {
    & > * {
      flex: 0 0 33.3333%;
    }
  }

  &--cell-4 {
    & > * {
      flex: 0 0 25%;
    }
  }

  &--cell-5 {
    & > * {
      flex: 0 0 20%;
    }
  }
}

.frame {
  padding: 96px 16px;
  margin: 0;
  font-size: 18px;
  line-height: 1.7;

  &:last-child {
    margin-bottom: 0;
  }
  &__wrapper {
    max-width: 1128px;
    margin: 0 auto;
  }
  &__title {
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.3;
    color: $color-style-white;
    text-align: center;

    .flagged {
      position: relative;
      display: inline-block;
      padding: 0 0.05em 0 0.25em;
      background: $color-style-blue;

      &:after {
        content: '';
        position: absolute;
        right: -24px;
        width: 24px;
        top: 0;
        bottom: 0;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 126 55' width='126' height='55' %3E%3Cpath fill='%236046FF' d='M0 0h126l-16 27.5L126 55H0V0z'/%3E%3C/svg%3E")
          right center no-repeat;
        background-size: auto 100%;
      }
    }
  }
  &__subtitle {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.3;
    color: $color-element-text-light;
    text-align: center;
    max-width: 768px;
    margin: 32px auto 0;
  }
  &__header {
    margin: 0 0 72px;
  }

  &--about {
    background-color: $color-bg-80;
  }
  &--about & {
    &__wrapper {
      max-width: 936px;
    }
    @media (min-width: 768.1px) {
      &__wrapper {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: 1fr 1fr;
      }
      &__title {
        text-align: left;
      }
    }
  }
  &--accent {
    background-color: $color-bg-80;
  }
  &--accent & {
    &__subtitle {
      color: $color-style-white;
    }
  }
  &--started {
    padding-bottom: 0;
  }
  &--started & {
    &__wrapper {
      max-width: none;
    }
    &__header,
    &__body {
      max-width: 1128px;
      margin-left: auto;
      margin-right: auto;
    }
    &__footer {
      margin: 160px -20px 0;
      background: linear-gradient(104.04deg, #6046ff 0%, #22d49f 100%);
      height: 108px;

      .action--main {
        top: -50px;
        height: 158px;
      }
    }
  }

  @media (max-width: 800px) {
    padding: 48px 16px;
  }
}
