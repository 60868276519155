.dashboard {
  padding: 0 8px;
  margin: 0 auto;
  max-width: $content-width;

  &__header {
    margin: 160px 0 32px;
    @media (max-width: 768px) {
      margin: 88px 0 32px;
      font-size: 36px;
    }
  }
  &__title {
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }

  &__demo {
    margin: 0 auto;
    margin-bottom: 32px;
    max-width: 300px;
    padding: 0 8px;

    &-button {
      margin-bottom: 5px;
    }

    @media (max-width: 768px) {
      margin-bottom: 24px;
      max-width: 200px;
    }
  }

  &__text {
    text-align: center;
    max-width: 90%;
    opacity: 0.7;
    margin-bottom: 32px;
  }

  &-content {
    display: grid;
    grid-gap: 8px;
    @media (min-width: 1024.1px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: max-content;

      &__stats {
        grid-row: span 2;
      }
      &__projects {
        grid-row-start: 2;
      }
    }
  }
}
