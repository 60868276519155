.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 8px;
  color: $color-element-text-light;
  height: 64px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 1.5;
  background: $color-bg-50;
  transition: background-color 0.25s ease, color 0.25s ease;
  white-space: nowrap;

  &:hover {
    background-color: $color-style-grey;
    color: $color-style-white;
  }

  .icon {
    flex: 0 0 auto;
    width: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    svg * {
      stroke: currentColor;
    }
  }

  &--main,
  &--accept,
  &--primary {
    background-color: $color-style-green;
    color: $color-style-white;

    &:hover {
      background-color: $color-hover-green-light;
    }
  }

  &--secondary,
  &--decline {
    background-color: $color-style-red;
    color: $color-style-white;

    &:hover {
      background-color: $color-hover-red;
    }
  }

  &--tretiary,
  &--url {
    background-color: $color-bg-60;
    color: $color-style-purple;
    height: 40px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5;
    width: auto;
    border-radius: 8px;

    &:hover {
      background-color: $color-element-btn-hover;
    }
  }

  &--action {
    background-color: $color-style-purple;
    color: $color-style-white;

    &:hover {
      background-color: $color-hover-purple;
    }
  }

  &--switch {
    background: transparent;
    color: $color-style-purple;
    border-radius: 8px !important;
    white-space: nowrap;

    &:hover {
      background: transparent;
      color: $color-hover-purple;
    }

    &.active {
      background-color: $color-style-purple;
      color: $color-style-white;
    }
  }

  &--link {
    height: auto;
    padding: 0;
    width: auto;
    background: none;
    color: #9488f0;

    &:hover {
      background: none;
      color: #afa5fb;
    }
  }

  &.disable,
  &:disabled,
  &.disabled {
    background-color: transparent;
    border: 1px solid $color-element-btn-hover;
    color: $color-element-text;
    pointer-events: none;
  }

  &--buy:disabled,
  &--sell:disabled {
    background-color: $color-bg-60;
    border: 1px solid $color-style-grey;
  }

  &:hover {
    cursor: pointer;
  }

  &--xl {
    height: 56px;
    font-size: 18px;
    border-radius: 8px;
    padding-left: 32px;
    padding-right: 32px;
  }

  &--lg {
    height: 48px;
    font-size: 18px;
    border-radius: 8px;
    padding-left: 32px;
    padding-right: 32px;
  }

  &--md {
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
  }

  &--sm {
    height: 32px;
    border-radius: 4px;
    font-size: 14px;
  }

  &--xs {
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
