.token-ico {
  width: 40px;
  height: 40px;
  border: 2px transparent solid;
  border-radius: 50%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3ClinearGradient id='a' x1='29.7388' x2='6.9498' y1='12.1431' y2='30.5284' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.0812288' stop-color='%239488f0'/%3E%3Cstop offset='1' stop-color='%239488f0' stop-opacity='0'/%3E%3C/linearGradient%3E%3Cpath fill='url(%23a)' fill-rule='evenodd' d='M20 4C11.2 4 4 11.2 4 20c0 4.7 2.1 9 5.3 11.9l-2.7 3C2.6 31.2 0 25.9 0 20 0 9 9 0 20 0s20 9 20 20-9 20-20 20c-1.1 0-2-.9-2-2s.9-2 2-2c8.8 0 16-7.2 16-16S28.8 4 20 4z' clip-rule='evenodd'/%3E%3Cpath fill='%239488f0' fill-rule='evenodd' d='M40 20c0 11-9 20-20 20-1.1 0-2-.9-2-2s.9-2 2-2c8.8 0 16-7.2 16-16 1.2 1.2 1.6 1.8 4 0z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-size: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease;
  }

  & > svg,
  &__image {
    width: 32px;
    height: 32px;
    object-fit: cover;
    margin: 2px;
    border-radius: 50%;

    svg {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }

  &--sm {
    width: 32px;
    height: 32px;

    &:before {
      width: 48px;
      height: 48px;
      margin: -24px 0 0 -24px;
    }
  }

  &--sm > svg,
  &--sm &__image {
    width: 24px;
    height: 24px;
  }

  &--xs {
    width: 24px;
    height: 24px;
    border: 1px transparent solid;

    &:before {
      width: 40px;
      height: 40px;
      margin: -20px 0 0 -20px;
    }
  }

  &--xs > svg,
  &--xs &__image {
    width: 18px;
    height: 18px;
    margin: 2px;
  }

  &--loading:before {
    opacity: 1;
    visibility: visible;
    animation: token-icon-loading 1s linear infinite;
  }

  &.bsc,
  &.polygon,
  &--network-bsc,
  &--network-polygon {
    &:after {
      content: '';
      position: absolute;
      right: -2px;
      bottom: -2px;
      width: 10px;
      height: 10px;
      border: 2px #1e242c solid;
      border-radius: 50%;
    }
  }

  &.bsc,
  &--network-bsc {
    border-color: #ff8a1f;

    &:after {
      background: #ff8a1f;
    }
  }

  &.polygon,
  &--network-polygon {
    border-color: #863ff8;

    &:after {
      background: #863ff8;
    }
  }
}

@keyframes token-icon-loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
}

.icon img {
  max-width: 100%;
}
